import React from 'react';
import { connect } from 'redux-bundler-react';

export default connect(
  ({ }) => (
    <div className='container-fluid'>
      Map
    </div>
  )
);
