export const gearCodeOptions = [
  { value: 'BSH' },
  { value: 'BSHD' },
  { value: 'BSHU' },
  { value: 'BSQ' },
  { value: 'BSQD' },
  { value: 'BSQU' },
  { value: 'BSR' },
  { value: 'BSRD' },
  { value: 'BSRU' },
  { value: 'BT' },
  { value: 'BT8' },
  { value: 'BTF' },
  { value: 'DGN14' },
  { value: 'DGN18' },
  { value: 'DGN41' },
  { value: 'DGN81' },
  { value: 'DMFT' },
  { value: 'DN' },
  { value: 'EF' },
  { value: 'EFTB' },
  { value: 'FISH' },
  { value: 'FN' },
  { value: 'FN18' },
  { value: 'FN34' },
  { value: 'FN36' },
  { value: 'G4IN' },
  { value: 'GN14' },
  { value: 'GN14G' },
  { value: 'GN14T' },
  { value: 'GN18' },
  { value: 'GN18G' },
  { value: 'GN18T' },
  { value: 'GN3' },
  { value: 'GN300' },
  { value: 'GN41' },
  { value: 'GN41G' },
  { value: 'GN41T' },
  { value: 'GN81' },
  { value: 'GN81G' },
  { value: 'GN81T' },
  { value: 'GNH3' },
  { value: 'GNH3T' },
  { value: 'GNM18' },
  { value: 'GNM25' },
  { value: 'GNM31' },
  { value: 'GNM3C' },
  { value: 'GNM3T' },
  { value: 'GNM81' },
  { value: 'GNMN3' },
  { value: 'HN' },
  { value: 'HTN' },
  { value: 'LDN1000' },
  { value: 'LDN500' },
  { value: 'LDN750' },
  { value: 'MF' },
  { value: 'MFD' },
  { value: 'MFT' },
  { value: 'MOT02' },
  { value: 'MT8' },
  { value: 'NEF' },
  { value: 'OT01' },
  { value: 'OT02' },
  { value: 'OT03' },
  { value: 'OT04' },
  { value: 'OT05' },
  { value: 'OT05U' },
  { value: 'OT16' },
  { value: 'OT16C' },
  { value: 'OT16L' },
  { value: 'OT16S' },
  { value: 'OT22' },
  { value: 'OT22C' },
  { value: 'OT22L' },
  { value: 'OT22S' },
  { value: 'OT8' },
  { value: 'OT8P' },
  { value: 'POT02' },
  { value: 'POT8' },
  { value: 'RH' },
  { value: 'SHN' },
  { value: 'SL' },
  { value: 'ST25T' },
  { value: 'STN' },
  { value: 'STN25' },
  { value: 'STN2T' },
  { value: 'STN50' },
  { value: 'STNT' },
  { value: 'TELM' },
  { value: 'TL1' },
  { value: 'TLC1' },
  { value: 'TLC1S' },
  { value: 'TLC1T' },
  { value: 'TLC2' },
  { value: 'TLC2S' },
  { value: 'TLC2T' },
  { value: 'TLC3' },
  { value: 'TLC3T' },
  { value: 'TLC4' },
  { value: 'TLC5' },
  { value: 'TLC6' },
  { value: 'TLC7' },
  { value: 'TLC8' },
  { value: 'TLC9' },
  { value: 'TLO1' },
  { value: 'TLO2' },
  { value: 'TLO3' },
  { value: 'TLS1' },
  { value: 'TLS2' },
  { value: 'TLS3' },
  { value: 'TMF' },
  { value: 'TN' },
  { value: 'TN11' },
  { value: 'TN12' },
  { value: 'TN13' },
  { value: 'TN2' },
  { value: 'TN25' },
  { value: 'TN3' },
  { value: 'TN38' },
  { value: 'TN48' },
  { value: 'TN483' },
  { value: 'TN50' },
  { value: 'TN610' },
  { value: 'TNG' },
  { value: 'TOT02' },
  { value: 'TRL1' },
  { value: 'TRL2' },
  { value: 'TTLC1' },
  { value: 'TTLC2' },
  { value: 'XHN' },
];

export const macroOptions = [
  { value: 'BRAD' },
  { value: 'CHXO' },
  { value: 'CONF' },
  { value: 'DEND' },
  { value: 'DRNG' },
  { value: 'DTWT' },
  { value: 'FDPN' },
  { value: 'FTBW' },
  { value: 'ISB' },
  { value: 'OSB' },
  { value: 'SCCL' },
  { value: 'SCCS' },
  { value: 'SCN' },
  { value: 'TRIB' },
  { value: 'TRML' },
  { value: 'TRMS' },
  { value: 'WILD' },
];

export const mesoOptions = [
  { value: 'BARS' },
  { value: 'CHNB' },
  { value: 'DTWT' },
  { value: 'FMCD' },
  { value: 'ITIP' },
  { value: 'OTHE' },
  { value: 'POOL' },
  { value: 'TLWG' },
];

export const microStructureOptions = [
  { text: 'Bank Line', value: 7 },
  { text: 'Channel Sand Bar', value: 6 },
  { text: 'Chevron', value: 5 },
  { text: 'Chute', value: 8 },
  { text: 'Highly Engineered', value: 9 },
  { text: 'Kicker Dike', value: 3 },
  { text: 'L Dike', value: 1 },
  { text: 'Rootless', value: 4 },
  { text: 'Wing Dike', value: 2 },
  { text: 'Not Described', value: 0 },
];

export const setSite_1_2Options = [
  { value: '1', text: 'L DIKE - POOL - BANK NOTCH' },
  { value: '2', text: 'L DIKE - POOL - TOP NOTCH' },
  { value: '3', text: 'L DIKE - POOL - SIDE NOTCH' },
  { value: '4', text: 'L DIKE - POOL - TIP' },
  { value: '5', text: 'L DIKE - POOL - NO NOTCH' },
  { value: '6', text: 'L DIKE - POOL - NOTCH (UNDEFINED)' },
  { value: '7', text: 'L DIKE - BAR - BANK' },
  { value: '8', text: 'L DIKE - BAR - BANK SIDE' },
  { value: '9', text: 'L DIKE - BAR - CHANNEL SIDE' },
  { value: '10', text: 'L DIKE - BAR - TAIL' },
  { value: '11', text: 'L DIKE - BAR - HEAD' },
  { value: '12', text: 'L DIKE - BAR - CROWN' },
  { value: '13', text: 'L DIKE - BAR - DIKE' },
  { value: '14', text: 'L DIKE - BAR - BRAIDED' },
  { value: '15', text: 'L DIKE - OPEN WATER - INSIDE EDDY' },
  { value: '16', text: 'L DIKE - OPEN WATER - OUT SIDE EDDY' },
  { value: '17', text: 'L DIKE - OPEN WATER - EDDY LINE' },
  { value: '18', text: 'L DIKE - OPEN WATER - LIP' },
  { value: '19', text: 'L DIKE - OPEN WATER - FLAT' },
  { value: '20', text: 'L DIKE - BANK - REVETMENT' },
  { value: '21', text: 'L DIKE - BANK - RED ROCK' },
  { value: '22', text: 'L DIKE - BANK - PILINGS' },
  { value: '23', text: 'L DIKE - BANK - NATURAL' },
  { value: '24', text: 'L DIKE - BANK - NATURAL STEEP' },
  { value: '25', text: 'L DIKE - COMBINED - POOL AND BAR' },
  { value: '26', text: 'L DIKE - COMBINED - POOL AND OPEN WATER' },
  { value: '27', text: 'L DIKE - COMBINED - BAR AND OPEN WATER' },
  { value: '28', text: 'L DIKE - COMBINED - DIKE TIP AND OPEN WATER' },
  { value: '29', text: 'L DIKE - COMBINED - BANK AND OPEN WATER' },
  { value: '30', text: 'L DIKE - COMBINED - UNDEFINED' },
  { value: '31', text: 'WING DIKE - POOL - BANK NOTCH' },
  { value: '32', text: 'WING DIKE - POOL - TOP NOTCH' },
  { value: '33', text: 'WING DIKE - POOL - TIP' },
  { value: '34', text: 'WING DIKE - POOL - NO NOTCH' },
  { value: '35', text: 'WING DIKE - POOL - NOTCH (UNDEFINED)' },
  { value: '36', text: 'WING DIKE - BAR - BANK' },
  { value: '37', text: 'WING DIKE - BAR - BANK SIDE' },
  { value: '38', text: 'WING DIKE - BAR - CHANNEL SIDE' },
  { value: '39', text: 'WING DIKE - BAR - TAIL' },
  { value: '40', text: 'WING DIKE - BAR - HEAD' },
  { value: '41', text: 'WING DIKE - BAR - CROWN' },
  { value: '42', text: 'WING DIKE - BAR - DIKE' },
  { value: '43', text: 'WING DIKE - BAR - BRAIDED' },
  { value: '44', text: 'WING DIKE - OPEN WATER - INSIDE EDDY' },
  { value: '45', text: 'WING DIKE - OPEN WATER - OUT SIDE EDDY' },
  { value: '46', text: 'WING DIKE - OPEN WATER - EDDY LINE' },
  { value: '47', text: 'WING DIKE - OPEN WATER - LIP' },
  { value: '48', text: 'WING DIKE - OPEN WATER - FLAT' },
  { value: '49', text: 'WING DIKE - BANK - REVETMENT' },
  { value: '50', text: 'WING DIKE - BANK - RED ROCK' },
  { value: '51', text: 'WING DIKE - BANK - PILINGS' },
  { value: '52', text: 'WING DIKE - BANK - NATURAL' },
  { value: '53', text: 'WING DIKE - BANK - NATURAL STEEP' },
  { value: '54', text: 'WING DIKE - COMBINED - POOL AND BAR' },
  { value: '55', text: 'WING DIKE - COMBINED - POOL AND OPEN WATER' },
  { value: '56', text: 'WING DIKE - COMBINED - BAR AND OPEN WATER' },
  { value: '57', text: 'WING DIKE - COMBINED - DIKE TIP AND OPEN WATER' },
  { value: '58', text: 'WING DIKE - COMBINED - BANK AND OPEN WATER' },
  { value: '59', text: 'WING DIKE - COMBINED - UNDEFINED' },
  { value: '60', text: 'KICKER DIKE - POOL - BANK NOTCH' },
  { value: '61', text: 'KICKER DIKE - POOL - SIDE NOTCH' },
  { value: '62', text: 'KICKER DIKE - POOL - TIP' },
  { value: '63', text: 'KICKER DIKE - POOL - NO NOTCH' },
  { value: '64', text: 'KICKER DIKE - POOL - NOTCH (UNDEFINED)' },
  { value: '65', text: 'KICKER DIKE - BAR - BANK' },
  { value: '66', text: 'KICKER DIKE - BAR - BANK SIDE' },
  { value: '67', text: 'KICKER DIKE - BAR - CHANNEL SIDE' },
  { value: '68', text: 'KICKER DIKE - BAR - TAIL' },
  { value: '69', text: 'KICKER DIKE - BAR - HEAD' },
  { value: '70', text: 'KICKER DIKE - BAR - CROWN' },
  { value: '71', text: 'KICKER DIKE - BAR - DIKE' },
  { value: '72', text: 'KICKER DIKE - BAR - BRAIDED' },
  { value: '73', text: 'KICKER DIKE - OPEN WATER - INSIDE EDDY' },
  { value: '74', text: 'KICKER DIKE - OPEN WATER - OUT SIDE EDDY' },
  { value: '75', text: 'KICKER DIKE - OPEN WATER - EDDY LINE' },
  { value: '76', text: 'KICKER DIKE - OPEN WATER - FLAT' },
  { value: '77', text: 'KICKER DIKE - BANK - REVETMENT' },
  { value: '78', text: 'KICKER DIKE - BANK - REDROCK' },
  { value: '79', text: 'KICKER DIKE - BANK - PILINGS' },
  { value: '80', text: 'KICKER DIKE - BANK - NATURAL' },
  { value: '81', text: 'KICKER DIKE - BANK - NATURAL STEEP' },
  { value: '82', text: 'KICKER DIKE - COMBINED - POOL AND BAR' },
  { value: '83', text: 'KICKER DIKE - COMBINED - POOL AND OPEN WATER' },
  { value: '84', text: 'KICKER DIKE - COMBINED - BAR AND OPEN WATER' },
  { value: '85', text: 'KICKER DIKE - COMBINED - DIKE TIP AND OPEN WATER' },
  { value: '86', text: 'KICKER DIKE - COMBINED - BANK AND OPEN WATER' },
  { value: '87', text: 'KICKER DIKE - COMBINED - UNDEFINED' },
  { value: '88', text: 'ROOTLESS - POOL - TOP' },
  { value: '89', text: 'ROOTLESS - POOL - NO NOTCH' },
  { value: '90', text: 'ROOTLESS - POOL - NOTCH (UNDEFINED)' },
  { value: '91', text: 'ROOTLESS - POOL - CHANNEL SIDE TIP' },
  { value: '92', text: 'ROOTLESS - POOL - BANK SIDE TIP' },
  { value: '93', text: 'ROOTLESS - BAR - BANK' },
  { value: '94', text: 'ROOTLESS - BAR - BANK SIDE' },
  { value: '95', text: 'ROOTLESS - BAR - CHANNEL SIDE' },
  { value: '96', text: 'ROOTLESS - BAR - TAIL' },
  { value: '97', text: 'ROOTLESS - BAR - HEAD' },
  { value: '98', text: 'ROOTLESS - BAR - CROWN' },
  { value: '99', text: 'ROOTLESS - BAR - DIKE' },
  { value: '100', text: 'ROOTLESS - BAR - BRAIDED' },
  { value: '101', text: 'ROOTLESS - OPEN WATER - INSIDE EDDY' },
  { value: '102', text: 'ROOTLESS - OPEN WATER - OUT SIDE EDDY' },
  { value: '103', text: 'ROOTLESS - OPEN WATER - EDDY LINE' },
  { value: '104', text: 'ROOTLESS - OPEN WATER - LIP' },
  { value: '105', text: 'ROOTLESS - OPEN WATER - FLAT' },
  { value: '106', text: 'ROOTLESS - BANK - REVETMENT' },
  { value: '107', text: 'ROOTLESS - BANK - REDROCK' },
  { value: '108', text: 'ROOTLESS - BANK - PILINGS' },
  { value: '109', text: 'ROOTLESS - BANK - NATURAL' },
  { value: '110', text: 'ROOTLESS - BANK - NATURAL STEEP' },
  { value: '111', text: 'ROOTLESS - COMBINED - POOL AND BAR' },
  { value: '112', text: 'ROOTLESS - COMBINED - POOL AND OPEN WATER' },
  { value: '113', text: 'ROOTLESS - COMBINED - BAR AND OPEN WATER' },
  { value: '114', text: 'ROOTLESS - COMBINED - DIKE TIP AND OPEN WATER' },
  { value: '115', text: 'ROOTLESS - COMBINED - UNDEFINED' },
  { value: '116', text: 'CHEVRON - POOL - NO NOTCH' },
  { value: '117', text: 'CHEVRON - POOL - NOTCH (UNDEFINED)' },
  { value: '118', text: 'CHEVRON - POOL - CHANNEL SIDE TIP' },
  { value: '119', text: 'CHEVRON - POOL - BANK SIDE TIP' },
  { value: '120', text: 'CHEVRON - POOL - NOTCH SCOUR' },
  { value: '121', text: 'CHEVRON - BAR - BANK' },
  { value: '122', text: 'CHEVRON - BAR - BANK SIDE' },
  { value: '123', text: 'CHEVRON - BAR - CHANNEL SIDE' },
  { value: '124', text: 'CHEVRON - BAR - TAIL' },
  { value: '125', text: 'CHEVRON - BAR - HEAD' },
  { value: '126', text: 'CHEVRON - BAR - CROWN' },
  { value: '127', text: 'CHEVRON - BAR - DIKE' },
  { value: '128', text: 'CHEVRON - BAR - BRAIDED' },
  { value: '129', text: 'CHEVRON - OPEN WATER - INSIDE EDDY' },
  { value: '130', text: 'CHEVRON - OPEN WATER - OUT SIDE EDDY' },
  { value: '131', text: 'CHEVRON - OPEN WATER - EDDY LINE' },
  { value: '132', text: 'CHEVRON - OPEN WATER - LIP' },
  { value: '133', text: 'CHEVRON - OPEN WATER - FLAT' },
  { value: '134', text: 'CHEVRON - BANK - REVETMENT' },
  { value: '135', text: 'CHEVRON - BANK - REDROCK' },
  { value: '136', text: 'CHEVRON - BANK - PILINGS' },
  { value: '137', text: 'CHEVRON - BANK - NATURAL' },
  { value: '138', text: 'CHEVRON - BANK - NATURAL STEEP' },
  { value: '139', text: 'CHEVRON - COMBINED - POOL AND BAR' },
  { value: '140', text: 'CHEVRON - COMBINED - POOL AND OPEN WATER' },
  { value: '141', text: 'CHEVRON - COMBINED - BAR AND OPEN WATER' },
  { value: '142', text: 'CHEVRON - COMBINED - DIKE TIP AND OPEN WATER' },
  { value: '143', text: 'CHEVRON - COMBINED - UNDEFINED' },
  { value: '144', text: 'CHANNEL SAND BAR - BAR - BANK' },
  { value: '145', text: 'CHANNEL SAND BAR - BAR - BANK SIDE' },
  { value: '146', text: 'CHANNEL SAND BAR - BAR - CHANNEL SIDE' },
  { value: '147', text: 'CHANNEL SAND BAR - BAR - TAIL' },
  { value: '148', text: 'CHANNEL SAND BAR - BAR - HEAD' },
  { value: '149', text: 'CHANNEL SAND BAR - BAR - CROWN' },
  { value: '150', text: 'CHANNEL SAND BAR - BAR - DIKE' },
  { value: '151', text: 'CHANNEL SAND BAR - BAR - BRAIDED' },
  { value: '152', text: 'CHANNEL SAND BAR - BAR - SHOAL' },
  { value: '153', text: 'CHANNEL SAND BAR - OPEN WATER - INSIDE EDDY' },
  { value: '154', text: 'CHANNEL SAND BAR - OPEN WATER - OUT SIDE EDDY' },
  { value: '155', text: 'CHANNEL SAND BAR - OPEN WATER - EDDY LINE' },
  { value: '156', text: 'CHANNEL SAND BAR - OPEN WATER - FLAT' },
  { value: '157', text: 'CHANNEL SAND BAR - BANK - REVETMENT' },
  { value: '158', text: 'CHANNEL SAND BAR - BANK - REDROCK' },
  { value: '159', text: 'CHANNEL SAND BAR - BANK - PILINGS' },
  { value: '160', text: 'CHANNEL SAND BAR - BANK - NATURAL' },
  { value: '161', text: 'CHANNEL SAND BAR - BANK - NATURAL STEEP' },
  { value: '162', text: 'CHANNEL SAND BAR - COMBINED - BAR AND OPEN WATER' },
  { value: '163', text: 'CHANNEL SAND BAR - COMBINED - DIKE TIP AND OPEN WATER' },
  { value: '164', text: 'CHANNEL SAND BAR - COMBINED - BANK AND OPEN WATER' },
  { value: '165', text: 'CHANNEL SAND BAR - COMBINED - UNDEFINED' },
  { value: '166', text: 'BANK LINE - BANK - REVETMENT' },
  { value: '167', text: 'BANK LINE - BANK - REDROCK' },
  { value: '168', text: 'BANK LINE - BANK - PILINGS' },
  { value: '169', text: 'BANK LINE - BANK - NATURAL' },
  { value: '170', text: 'BANK LINE - OPEN WATER - INSIDE EDDY' },
  { value: '171', text: 'BANK LINE - OPEN WATER - OUT SIDE EDDY' },
  { value: '172', text: 'BANK LINE - OPEN WATER - EDDY LINE' },
  { value: '173', text: 'BANK LINE - OPEN WATER - LIP' },
  { value: '174', text: 'BANK LINE - OPEN WATER - FLAT' },
  { value: '175', text: 'BANK LINE - SCALLOP - NA' },
  { value: '176', text: 'BANK LINE - COMBINED - BANK AND OPEN WATER' },
  { value: '177', text: 'BANK LINE - COMBINED - BANK AND SCALLOP' },
  { value: '178', text: 'BANK LINE - COMBINED - UNDEFINED' },
  { value: '179', text: 'CHUTE - POOL - BANK NOTCH' },
  { value: '180', text: 'CHUTE - POOL - NOTCH' },
  { value: '181', text: 'CHUTE - POOL - TIP' },
  { value: '182', text: 'CHUTE - POOL - NO NOTCH' },
  { value: '183', text: 'CHUTE - POOL - NOTCH (UNDEFINED)' },
  { value: '184', text: 'CHUTE - POOL - PLUNGE' },
  { value: '185', text: 'CHUTE - POOL - SNAG' },
  { value: '186', text: 'CHUTE - BAR - BANK' },
  { value: '187', text: 'CHUTE - BAR - BANK SIDE' },
  { value: '188', text: 'CHUTE - BAR - CHANNEL SIDE' },
  { value: '189', text: 'CHUTE - BAR - TAIL' },
  { value: '190', text: 'CHUTE - BAR - HEAD' },
  { value: '191', text: 'CHUTE - BAR - CROWN' },
  { value: '192', text: 'CHUTE - BAR - DIKE' },
  { value: '193', text: 'CHUTE - BAR - BRAIDED' },
  { value: '194', text: 'CHUTE - BAR - ISLAND' },
  { value: '195', text: 'CHUTE - OPEN WATER - INSIDE EDDY' },
  { value: '196', text: 'CHUTE - OPEN WATER - OUT SIDE EDDY' },
  { value: '197', text: 'CHUTE - OPEN WATER - EDDY LINE' },
  { value: '198', text: 'CHUTE - OPEN WATER - LIP' },
  { value: '199', text: 'CHUTE - OPEN WATER - FLAT' },
  { value: '200', text: 'CHUTE - BANK - REVETMENT' },
  { value: '201', text: 'CHUTE - BANK - REDROCK' },
  { value: '202', text: 'CHUTE - BANK - PILINGS' },
  { value: '203', text: 'CHUTE - BANK - NATURAL' },
  { value: '204', text: 'CHUTE - BANK - NATURAL STEEP' },
  { value: '205', text: 'CHUTE - SCALLOP - NA' },
  { value: '206', text: 'CHUTE - COMBINED - POOL AND BAR' },
  { value: '207', text: 'CHUTE - COMBINED - POOL AND OPEN WATER' },
  { value: '208', text: 'CHUTE - COMBINED - BAR AND OPEN WATER' },
  { value: '209', text: 'CHUTE - COMBINED - DIKE TIP AND OPEN WATER' },
  { value: '210', text: 'CHUTE - COMBINED - BANK AND OPEN WATER' },
  { value: '211', text: 'CHUTE - COMBINED - BANK AND SCALLOP' },
  { value: '212', text: 'CHUTE - COMBINED - UNDEFINED' },
  { value: '213', text: 'CHUTE - TIE CHANNEL - INFLOW HEAD' },
  { value: '214', text: 'CHUTE - TIE CHANNEL - INFLOW BODY' },
  { value: '215', text: 'CHUTE - TIE CHANNEL - INFLOW CONFLUENCE' },
  { value: '216', text: 'CHUTE - TIE CHANNEL - OUTFLOW HEAD' },
  { value: '217', text: 'CHUTE - TIE CHANNEL - OUTFLOW BODY' },
  { value: '218', text: 'CHUTE - TIE CHANNEL - OUTFLOW CONFLUENCE' },
  { value: '219', text: 'CHUTE - TIE CHANNEL - BACKWATER' },
  { value: '220', text: 'CHUTE - SIDE CHNL SMALL - HEAD' },
  { value: '221', text: 'CHUTE - SIDE CHNL SMALL - BODY' },
  { value: '222', text: 'CHUTE - SIDE CHNL SMALL - CONFLUENCE' },
  { value: '223', text: 'HIGHLY ENGINEERED - POOL - BANK NOTCH' },
  { value: '224', text: 'HIGHLY ENGINEERED - POOL - TOP NOTCH' },
  { value: '225', text: 'HIGHLY ENGINEERED - POOL - SIDE NOTCH' },
  { value: '226', text: 'HIGHLY ENGINEERED - POOL - TIP' },
  { value: '227', text: 'HIGHLY ENGINEERED - POOL - NO NOTCH' },
  { value: '228', text: 'HIGHLY ENGINEERED - POOL - NOTCH (UNDEFINED)' },
  { value: '229', text: 'HIGHLY ENGINEERED - BAR - BANK' },
  { value: '230', text: 'HIGHLY ENGINEERED - BAR - BANK SIDE' },
  { value: '231', text: 'HIGHLY ENGINEERED - BAR - CHANNEL SIDE' },
  { value: '232', text: 'HIGHLY ENGINEERED - BAR - TAIL' },
  { value: '233', text: 'HIGHLY ENGINEERED - BAR - HEAD' },
  { value: '234', text: 'HIGHLY ENGINEERED - BAR - CROWN' },
  { value: '235', text: 'HIGHLY ENGINEERED - BAR - DIKE' },
  { value: '236', text: 'HIGHLY ENGINEERED - BAR - BRAIDED' },
  { value: '237', text: 'HIGHLY ENGINEERED - OPEN WATER - INSIDE EDDY' },
  { value: '238', text: 'HIGHLY ENGINEERED - OPEN WATER - OUT SIDE EDDY' },
  { value: '239', text: 'HIGHLY ENGINEERED - OPEN WATER - EDDY LINE' },
  { value: '240', text: 'HIGHLY ENGINEERED - OPEN WATER - LIP' },
  { value: '241', text: 'HIGHLY ENGINEERED - OPEN WATER - FLAT' },
  { value: '242', text: 'HIGHLY ENGINEERED - BANK - REVETMENT' },
  { value: '243', text: 'HIGHLY ENGINEERED - BANK - REDROCK' },
  { value: '244', text: 'HIGHLY ENGINEERED - BANK - PILINGS' },
  { value: '245', text: 'HIGHLY ENGINEERED - BANK - NATURAL' },
  { value: '246', text: 'HIGHLY ENGINEERED - BANK - NATURAL STEEP' },
  { value: '247', text: 'HIGHLY ENGINEERED - SCALLOP - NA' },
  { value: '248', text: 'HIGHLY ENGINEERED - COMBINED - POOL AND BAR' },
  { value: '249', text: 'HIGHLY ENGINEERED - COMBINED - POOL AND OPEN WATER' },
  { value: '250', text: 'HIGHLY ENGINEERED - COMBINED - BAR AND OPEN WATER' },
  { value: '251', text: 'HIGHLY ENGINEERED - COMBINED - DIKE TIP AND OPEN WATER' },
  { value: '252', text: 'HIGHLY ENGINEERED - COMBINED - BANK AND OPEN WATER' },
  { value: '253', text: 'HIGHLY ENGINEERED - COMBINED - BANK AND SCALLOP' },
  { value: '254', text: 'HIGHLY ENGINEERED - COMBINED - UNDEFINED' },
];

export const setSite_3Options = [
  { text: 'UNKNOWN', value: 0 },
  { text: 'BRIDGE PILINGS', value: 1 },
  { text: 'CARS', value: 2 },
  { text: 'PILINGS', value: 3 },
  { text: 'SNAGS', value: 4 },
  { text: 'CREEK MOUTH', value: 5 },
  { text: 'SUB HERB VEG', value: 6 },
  { text: 'SUB WOODY VEG', value: 7 },
  { text: 'LOG PILE', value: 8 },
  { text: 'OTHER', value: 9 },
];

export const u7Options = [
  { value: 'BS', text: 'Broodstock' },
  { value: 'CP', text: 'Capture Probability' },
  { value: 'EB', text: 'Experimental Bait' },
  { value: 'FP', text: 'Floodplain' },
  { value: 'SE', text: 'Transmitter Seeding' },
];

// Search Effort Form Select Options
export const searchTypeOptions = [
  { value: 'BS', text: 'BS' },
  { value: 'IN', text: 'IN' },
  { value: 'RS', text: 'RS' },
  { value: 'TA', text: 'TA' },
];

// Telemetry Form Select Options
export const frequencyIdOptions = [
  { value: 1, text: 'A69-1602' },
  { value: 2, text: 'R_149.760_3' },
  { value: 3, text: 'R_149.620_3' },
  { value: 4, text: 'A_180.0' },
  { value: 5, text: 'A_76.8_1.1.4' },
  { value: 6, text: 'A_200_1.14.1' },
  { value: 7, text: 'A_69.0' },
  { value: 8, text: 'A69-1601' },
];

export const positionConfidenceOptions = [
  { value: 1, text: '1' },
  { value: 2, text: '2' },
  { value: 3, text: '3' },
];

// Fish Form Select Options
export const finCurlOptions = [
  { value: '', text: 'N/A' },
  { value: 'Y', text: 'Yes' },
  { value: 'N', text: 'No' },
];

export const raySpineOptions = [
  { value: 'X' },
];

export const scaleOptions = [
  { value: 'X' },
  { value: 'G' },
];

export const baitOptions = [
  { value: 'C' },
  { value: 'F' },
  { value: 'L' },
  { value: 'W' },
];

// Procedure Form Select Options
export const purposeOptions = [
  { value: 'II' },
  { value: 'RI' },
  { value: 'EV' },
  { value: 'SE' },
  { value: 'RT' },
];

export const sexOptions = [
  { value: 'F', text: 'Female' },
  { value: 'M', text: 'Male' },
  { value: 'U', text: 'Unknown' },
];

export const spawnEvaluationOptions = [
  { value: 'NS', text: 'Non-Spawn (NS)' },
  { value: 'PS', text: 'Partial Spawn (PS)' },
  { value: 'CP', text: 'Complete Spawn (CP)' },
  { value: 'UN', text: 'Unknown (UN)' },
  { value: 'NA', text: 'Not Applicable (NA)' },
];

export const evalLocationsOptions = [
  { value: 'F', text: 'Field (F)' },
  { value: 'GP', text: 'Gavins Point NFH (GP)' },
  { value: 'BP', text: 'Blind Pony SFH (BP)' },
  { value: 'NS', text: 'Neosho NFH (NS)' },
  { value: 'GR', text: 'GR' },
];

export const visualAssessmentOptions = [
  { value: 'R' },
  { value: 'N' },
  { value: 'Z'},
];

export const YNNumOptions = [
  { text: 'Y', value: 1 },
  { text: 'N', value: 0 },
];

export const YNTextOptions = [
  { text: 'Y' },
  { text: 'N' },
];

export const ScuteOptions = [
  { value: 'N' },
  { value: 'R' },
  { value: 'L' },
];

export const HVXOptions = [
  { value: 'H', text: 'Horizontal Position' },
  { value: 'V', text: 'Vertical Position' },
  { value: 'X', text: 'Undetermined Position' },
];

export const ERELOptions = [
  {value: 'G', text: 'Green'},
  {value: 'O', text: 'Orange'},
  {value: 'R', text: 'Red'},
  {value: 'P', text: 'Pink'},
  {value: 'Y', text: 'Yellow'},
  {value: 'W', text: 'White'},
  {value: 'V', text: 'Purple'},
  {value: 'B', text: 'Brown'},
  {value: 'K', text: 'Black'},
  {value: 'U', text: 'Blue'},
  {value: 'N', text: 'None'},
  {value: 'x', text: 'Undetermined'},
];